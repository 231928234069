import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Loading } from "./notFound.js";
import { ModalSlika } from "./modalSlika.js";
import { Faq } from "./faq.js";
import { Footer } from "./footer.js";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const ADRESA = "";
//export const ADRESA = "/lipavita";

export const Kontekst = React.createContext();

function Glavna() {

  const [brSlika, setBrSlika] = React.useState(0);
  const [lang, setLang] = React.useState("hr");

  const [loadSw, setLoadSw] = React.useState(false);
  const [brUcitanih, setBrUcitanih] = React.useState(0);
  const [bojaRotEl, setBojaRotEl] = React.useState("white");
  const [brSlika1, setBrSlika1] = React.useState(-1);
  const [swModal, setSwModal] = React.useState(false);
  const [pomak, setPomak] = React.useState(0);

  // ovdje pocinje mehanizam za izracun sirine/visine ekrana kada pritisnemo gumb
  React.useEffect(()=>{
    loadFun();
  },[]); 

  function loadFun() {
    console.log("Cijela stranica je potpuno ucitana... " + Math.random());
    //setLoadSw(true);

    // helper kod za dizajn stranice
    document.addEventListener("keydown", (e) => {pritisakGumba(e)});
    let ell = document.querySelector("html");
    function pritisakGumba(e) {
      if (e.code === "KeyS") {
        console.log("sirina/visina prozora su " + ell.clientWidth + " / " + ell.clientHeight);
        console.log(Math.random());
      }
    }
  }

  function loadedPicFun() {
    setBrUcitanih((prev)=>{return prev+1});
  }
  // ovdje mehanizam zavrsava

  const r = React.useRef();
  const r1 = React.useRef();
  const r2 = React.useRef();
  const r3 = React.useRef();
  const r4 = React.useRef();

  const p = React.useRef();
  const p1 = React.useRef();

  const s0 = React.useRef();
  const s1 = React.useRef();
  const s2 = React.useRef();
  const s3 = React.useRef();
  const s4 = React.useRef();
  const s5 = React.useRef();

  const h = React.useRef();

  function loadedPicFun() {
    setBrUcitanih((prev)=>{return prev+1});
  }

  React.useEffect(()=>{
    if (brUcitanih === 3) {
      setLoadSw(true);
    }
    console.log("broj ucitanih je " + brUcitanih);
    
  },[brUcitanih]);

    /*sljedeci react.useEffect blokira scroll kada se modal otvori, zadaca div-a klase holder je da
    se mjenja iz position fixed u relative i obrnuto, te se pozicionira ovisno o trenutnom scrollu*/
    React.useEffect(()=>{  
      if (swModal) {
        
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setPomak(scrollTop);
  
        let element = h.current;
        let cssObj = window.getComputedStyle(element, null);
        let svojstvo = cssObj.getPropertyValue("top");
  
        let svojstvo1 = cssObj.getPropertyValue("height");
  
        console.log("modal je otvoren");
        console.log(svojstvo);
        console.log(svojstvo1);
  
        h.current.style.position = "fixed";
        h.current.style.top = -1*scrollTop + "px";
      } else {      
        let element = h.current;
        let cssObj = window.getComputedStyle(element, null);
        let svojstvo = cssObj.getPropertyValue("height");
        
        h.current.style.top = "0px";
        h.current.style.position = "relative";
        window.scrollTo(0, pomak);
  
        console.log("modal je zatvoren");
        console.log(svojstvo);
        
      }
    }, [swModal]);

  React.useEffect(()=>{
    r.current = setInterval(()=>{setBrSlika((prev)=>{return (prev+1)%3})}, 5000);
    r4.current.style.top = "0";

    return ()=>{clearInterval(r.current)};
  }, []);

  React.useEffect(()=>{
    if (loadSw) {
      p.current.style.display = "block";
    } else {
      p.current.style.display = "block";
    }
  }, [loadSw]);

  React.useEffect(()=>{
    if (brSlika === 0) {
      r3.current.style.opacity = "0";
      r1.current.style.opacity = "1";
      p1.current.style.color = "white"
      setBojaRotEl("white");
    } else if (brSlika === 1) {
      r1.current.style.opacity = "0";
      r2.current.style.opacity = "1";
      p1.current.style.color = "#07c5de"
      setBojaRotEl("#07c5de");
    } else {
      r2.current.style.opacity = "0";
      r3.current.style.opacity = "1";
      p1.current.style.color = "green"
      setBojaRotEl("green");
    }
  }, [brSlika]);

  React.useEffect(()=>{
    s0.current.style.opacity = "0";
    s1.current.style.opacity = "0";
    s2.current.style.opacity = "0";
    s3.current.style.opacity = "0";
    s4.current.style.opacity = "0";
    s5.current.style.opacity = "0";
    
    if (brSlika1 === 1) {
      s1.current.style.opacity = "1";
    } else if (brSlika1 === 2) {
      s2.current.style.opacity = "1";
    } else if (brSlika1 === 3) {
      s3.current.style.opacity = "1";
    } else if (brSlika1 === 4) {
      s4.current.style.opacity = "1";
    } else if (brSlika1 === 5) {
      s5.current.style.opacity = "1";
    } else {
      s0.current.style.opacity = "1";
    }
  }, [brSlika1]);

  return (
    <>
    <Loading sw={!loadSw}/>
    <div ref={p} className="glavna">
     <div ref={h} className="holder">
      {false ? <>
      <div ref={r1} id="slika1" className="slika"></div>
      <div ref={r2} id="slika2" className="slika"></div>
      <div ref={r3} id="slika3" className="slika"></div>
      </> : <>
      <img ref={r1} onLoad={loadedPicFun} src="slike/uber.png" id="slika1-img" className="slika-img"/>
      <img ref={r2} onLoad={loadedPicFun} src="slike/bolt.png" id="slika2-img" className="slika-img"/>
      <img ref={r3} onLoad={loadedPicFun} src="slike/dostavljaci.jpg" id="slika3-img" className="slika-img"/>
      </>}
      <div ref={r4} className="natpis">
        <div className="pomak"></div>
        <p ref={p1} className="natpis-el">{lang === "hr" ? "NOVA WEB APLIKACIJA ZA TAXI I DOSTAVLJAČKE FIRME" : "We are employing accross many cities in Croatia"}</p>
        <RotirajuciNatpisi boja={bojaRotEl}/>
      </div>
      <main className="main">
        <div className="uvod">
          <p className="uvod-tekst">Poštovani, zadovoljstvo mi je predstaviti novu web aplikaciju specijalno dizajniranu za dostavljačke i taksi firme. Aplikacija se sastoji od baze podataka na serveru. Ulaskom u administratorov account moguće je pristupati podacima o vozačima, vozilima, tjednim prometima / obračunima, transakcijama i potraživanjima. Na taj način u svakom trenutku imate pregled nad svim podacima. Vozači imaju vlastite račune kojima pristupaju preko internet browsera (mobitel ili desktop). Oni u svakom trenutku mogu provjeriti sve navedene podatke koji se odnose na njih. Aplikacija ih obaviještava kada su napravljene tjedne isplate, kolika su potraživanja i tjedni registrirani prometi i sve ostalo. Aplikacija će imati multijezičnu podršku za više jezika (i neeuropske jezike).</p>
        </div>
        <h2 className="naslov-problemi">Neki od problema koje aplikacija rješava</h2>
        <div className="pain-points">
          <div className="lijeva">
            <Kartica naslov="tjedni obračuni" kod={1} extraHeight={130} brSlika={brSlika1} setBrSlika={setBrSlika1} tekst="Bitno smanjenje vremena za izradu tjednih obračuna. Aplikacija učitava CSV fileove sa prometima i automatski radi obračune i time smanjuje pogreške i količinu rada koju treba uložiti. Također smanjuje zahtjeve na kvalificiranost radnika koji taj posao obavljaju.">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="ikona bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
              </svg>
            </Kartica> 

            <Kartica naslov="transparentnost isplata i potraživanja" kod={2} brSlika={brSlika1} setBrSlika={setBrSlika1} extraHeight={130} tekst="Aplikacija omogućuje potpunu transparentnost svih obavljenih isplata i stanja potraživanja na bilo koji datum. Na taj način se bitno smanjuju nesporazumi između vozača i firme, kao i vrijeme koje se troši na usporedbe evidencija i otklanjanje nesporazuma.">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="ikona bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
              </svg>
            </Kartica> 

            <Kartica naslov="centralizirana pohrana podataka" kod={3} brSlika={brSlika1} setBrSlika={setBrSlika1} extraHeight={150} tekst="Aplikacija omogućuje centraliziranu pohranu svih relevantnih podataka o radniku (broj mobitela, OIB, bankovni računi, adresa, itd). Podaci su vidljivi administratoru na bilo kojem browseru bilo gdje na svijetu. Ukoliko ih treba ažurirati, to može radnik napraviti preko svog računa, bez potrebe dolaska u ured.">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="ikona bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
              </svg>
            </Kartica> 

            <Kartica naslov="minimaliziranje grešaka u isplatama" kod={4} brSlika={brSlika1} setBrSlika={setBrSlika1} extraHeight={190} tekst="Greške u isplatama se bitno smanjuju, kao i gubici koje firma ima godišnje po radniku. Moja procjena na temelju razgovora sa firmama u industriji kao i na temelju mojih promatranja, je da tipična taksi firma izgubi minimalno oko 20-30 eura godišnje po radniku, kroz razne vidove sitnih grešaka (krivo obračunati doprinosi, troškovi fiskalizacije ili krivi dani najma vozila, koje radnik prešuti ukoliko idu njemu u korist).">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="ikona bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
              </svg>
            </Kartica> 

            <Kartica naslov="multijezična funkcionalnost" kod={5} brSlika={brSlika1} setBrSlika={setBrSlika1} extraHeight={160} tekst="Planirana je podrška za veći broj jezika, prvenstveno matičnih jezika vaših radnika (od nepalskog do arapskog). Odabirom željene zastavice, cijela aplikacija se prebacuje na odabrani jezik. Na taj način radnik dobija mnogo bolje razumijevanje svih relevantnih informacija. Time se minimaliziraju nesporazumi, potencijalni dolasci u ured i objašnjavanja na lošem engleskom jeziku.">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="ikona bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
              </svg>
            </Kartica> 
          </div>
          <div className="desna">
            <div className="slika-cont">
              <img ref={s0} src="slike/problem1.png" className="slika-strana" id="slika-strana-0"/>
            </div>
            <div className="slika-cont">
              <img ref={s1} src="slike/problem.png" className="slika-strana" id="slika-strana-1"/>
            </div>
            <div className="slika-cont">
              <img ref={s2} src="slike/transparentnostPotrazivanja.png" className="slika-strana" id="slika-strana-2"/>
            </div>
            <div className="slika-cont">
              <img ref={s3} src="slike/arhiva.png" className="slika-strana" id="slika-strana-3"/>
            </div>
            <div className="slika-cont">
              <img ref={s4} src="slike/loosingMoney.png" className="slika-strana" id="slika-strana-4"/>
            </div>
            <div className="slika-cont">
              <img ref={s5} src="slike/multipleLanguages.png" className="slika-strana" id="slika-strana-5"/>
            </div>
          </div>
        </div>
        <h2 className="naslov-problemi">Galerija screenshotova web aplikacije</h2>
        <div className="screenshotovi">
          <ModalSlika path="slike/screenshotovi/1.png" model="signup stranica" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/2.png" model="vozačev panel Podaci" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/3.png" model="vozačev panel Obračun" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/4.png" model="dno voz. panela Obračun" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/5.png" model="adminov panel Vozači" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/6.png" model="adminov panel Vozila" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/7.png" model="adminov panel Doprinosi" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/8.png" model="adminov panel Prometi" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/9.png" model="adminov panel Obračuni" setSwModal={setSwModal}/>
          <ModalSlika path="slike/screenshotovi/10.png" model="adminov panel Transakcije" setSwModal={setSwModal}/>
        </div>
        <div className="buffer1"></div>
        <h2 className="naslov-problemi" id="naslov-video-prezentacija">Video prezentacija</h2>
        <video className="video" controls>
		      <source src="videoprezentacija.mp4" type="video/mp4"/>
	      </video>
        <h2 className="naslov-problemi">Često postavljana pitanja</h2>
        <Faq/>
        <h2 className="naslov-problemi" id="naslov-video-prezentacija">Youtube video šetnja kroz aplikaciju</h2>
        <div className="container-video1">
          <iframe className="video1" src="https://www.youtube.com/embed/lRFpcFijDV0?si=WJCF93A90olJy917" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </main>
      <Footer/>
     </div>
    </div>
    </>
  )
}

function Kartica({children, extraHeight=200, naslov="naslov", 
  tekst="neki tekst", kod=1, brSlika=1, setBrSlika=()=>{return true}}) {
  const [sw, setSw] = React.useState(false);
  const [brKlik, setBrKlik] = React.useState(0);
  const r = React.useRef();
  const r1 = React.useRef();

  const karticaHeight = 100;

  React.useEffect(()=>{
    r.current.style.height = extraHeight + "px";
  }, []);

  React.useEffect(()=>{
    if (brSlika === kod && !sw) {
      setSw(true);
    } else if (brSlika !== kod && sw) {
      setSw(false);
    } 
  }, [brSlika]);

  React.useEffect(()=>{
    if (sw) {
      r1.current.style.height = (karticaHeight+extraHeight) + "px";
      setBrSlika(kod);
    } else {
      r1.current.style.height = karticaHeight + "px";
      //setBrSlika(-1);
    }
  }, [sw])

  React.useEffect(()=>{
    if (brKlik !== 0) {
      if (sw)  setBrSlika(-1);
      setSw((prev)=>{return !prev});
    }
    //console.log("novi brKlik je " + brKlik);
  }, [brKlik]);

  return (
    <div ref={r1} onClick={()=>{setBrKlik((prev)=>{return prev+1})}} className="kartica">
      <div className="kartica-el1">
        {children}
      </div>
      <div className="kartica-el2">
        <p className="nas">{naslov}</p>
      </div>
      <div ref={r} className="kartica-el3">
        <p className="tekst">{tekst}</p>
      </div>
    </div>
  )
}

function RotirajuciNatpisi({boja="yellow"}) {
  //const [elementi, setElementi] = React.useState(["Zagreb", "Split", "Rijeka", "Osijek", "Zadar", "Varaždin", "Karlovac", "Pula", "Dubrovnik", "Rovinj", "Poreč", "Koprivnica", "Križevci", "Sisak", "Virovitica"]);
  const [elementi, setElementi] = React.useState(["automatizacija tjednih obračuna", "centralizirana baza podataka", "management radnika i vozila", "praćenje transakcija i potraživanja", "multijezična podrška radnika", "customizacija web aplikacije", "nove funkcionalnosti po narudžbi"]);
  const [br, setBr] = React.useState(0);

  const r = React.useRef();

  React.useEffect(()=>{
    r.current = setInterval(()=>{setBr((prev)=>{return (prev+1)%(elementi.length)})}, 5000);

    return ()=>{clearInterval(r.current)};
  }, []);

  return (
    <div className="rotirajuci">
      {elementi.map((el, index)=>{
        if (index === br) {
          //console.log("upravo stavljamo trenutni " + Math.random());
          return <div style={{color: boja}} key={index} className="element trenutni">{el}</div>; 
        } else if (index === (br+(elementi.length)-1)%(elementi.length)) {
          return <div style={{color: boja}} key={index} className="element prethodni">{el}</div>  
        }
        //console.log("upravo stavljamo sljedeci " + Math.random());
        return <div style={{color: boja}} key={index} className="element sljedeci">{el}</div>
      })}
    </div>
  )
}

function Razvojna() {
  return (
    <div className="razvojna">
      <Faq/>
    </div>
  )
}

root.render(
  <React.StrictMode>
    {true ? <Glavna/> : <Razvojna />}
  </React.StrictMode>
);




