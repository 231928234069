import React from 'react';
import ReactDOM from 'react-dom/client';
import './footer.scss';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate
} from "react-router-dom";

export function Footer() {
    const [sw, setSw] = React.useState(false);
    const [tekst, setTekst] = React.useState("Facebook");
    const [showSw, setShowSw] = React.useState(false);
    const [showSw1, setShowSw1] = React.useState(false);
  
    function hover(e) {
      console.log("HOVER " + Math.random());
      console.log(e.target.classList);
      let clista = e.target.classList;
      if (clista.contains("facebook-ikona")) {
        setTekst("Facebook");
      } else if (clista.contains("twiter-ikona")) {
        setTekst("Twiter");
      } else {
        setTekst("Instangram");
      }
      setSw(true);
    }
  
    function unHover() {
      console.log("UNHOVER " + Math.random());
      setSw(false);
    }
    return (
      <footer className="footer">
        <div className="podaci">
          <h3>Kontakt:</h3>
          <div onClick={()=>{setShowSw((prev)=>{return !prev})}} className="show-button">{showSw ? "savic.slobodan@mail.com" : "pokaži email"}</div>
          <div onClick={()=>{setShowSw1((prev)=>{return !prev})}} className="show-button">{showSw1 ? "099 654 3442" : "pokaži tel./whatsApp"}</div>
        </div>

        <div className="copyright">
          <p>Copyright © 2023 Slobodan Savić | <br/><span className="design">Dizajn: www.slobodansavic.com</span></p>
        </div>
      </footer>
    )
  }
