import React from 'react';
import ReactDOM from 'react-dom/client';

import './faq.scss';

export function Faq() {
  const [brPolja, setBrPolja] = React.useState(-1);
  const [resizeBr, setResizeBr] = React.useState(0);

  React.useEffect(()=>{
    window.addEventListener("resize", resizeFun);

    return ()=>{
      window.removeEventListener("resize", resizeFun);
    }
  }, []);

  function resizeFun() {
    setResizeBr((prev)=>{return prev+1});
  }

  React.useEffect(()=>{
    console.log("Odabrano polje je " + brPolja);
  }, [brPolja]);

  return (
    <div className="faq">
      <Pitanje kod={1} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Tko sam ja?"/>
      <Odgovor kod={1} resizeBr={resizeBr} brPolja={brPolja} tekst="Ja sam Slobodan Savić, web developer iz Zagreba sa dvije godine iskustva. Trenutno razvijam poslovna rješenja za firme, bazirana na web tehnologijama. Aplikacije koje razvijam se pokreću iz web browsera, a tipično se spajaju na server sa bazom podataka. Stalno sam u potrazi za poslovnim nišama koje su zanemarene od strane softverske industrije. Na taj način razvijam specijalizirani softver za veći broj firmi u odabranom području, što omogućuje smanjenje cijene za krajnjeg korisnika."/>
      <Pitanje kod={2} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Za koga je ova aplikacija namjenjena?"/>
      <Odgovor kod={2} resizeBr={resizeBr} brPolja={brPolja} tekst="Aplikacija je napravljena specijalno za taksi i dostavljačke firme koje rade sa digitalnim platformama (Uber, Bolt, Glovo, Wolt, Bolt Food)."/>
      <Pitanje kod={3} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Što sve aplikacija može učiniti za mene?"/>
      <Odgovor kod={3} resizeBr={resizeBr} brPolja={brPolja} tekst="Aplikacija nudi centraliziranu bazu podataka sa informacijama o vozačima, vozilima, tjednim prometima, transakcijama, plaćenim doprinosima, obračunima i mnogo drugih stvari. Ona vam nudi mogućnost potpune automatizacije pravljenja tjednih obračuna na vrlo jednostavan način – učitavanjem csv fileova koje dobijate od npr. Ubera i Bolta, koje zatim procesira, pohranjuje sve podatke u bazu podataka i izrađuje sve tjedne obračune za sve vozače. Poslovni model omogućuje i dodavanje novih featura po vašoj želji koji mogu dodatno automatizirati vaše poslovanje."/>
      <Pitanje kod={4} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Što sve aplikacija može učiniti za moje radnike?"/>
      <Odgovor kod={4} resizeBr={resizeBr} brPolja={brPolja} tekst="Aplikacija nudi radniku pristup svim njemu relevantnim podacima preko njegovog računa. Radnik može pratiti tjedne promete, transakcije, obračune. Na taj način smanjuju se potreba za dolascima u ured i bilo kakve interakcije preko whatsAppa. U budućnosti će biti dodani i novi featuri, npr. osobni dnevnici prometa za radnika i sl. Također aplikacija će imati široku podršku za više jezika, uključujući neeuropske (npr. arapski, nepalski itd.). Na taj način bit će cijela u matičnom jeziku radnika. Planiram i dodavanje video knowledge baze koja će sadržavati videje o korištenju aplikacije, uvodne videje vaše firme za nove radnike, često postavljana pitanja itd. Moguća je ugradnja novih funkcionalnosti koje vi/ vaši radnici smislite."/>
      <Pitanje kod={5} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Koje tehnologije su korištene za izradu web aplikacije?"/>
      <Odgovor kod={5} resizeBr={resizeBr} brPolja={brPolja} tekst="Na serveru sam koristio Ruby on Rails, vrlo fleksibilan, efikasan i popularan web framework, idealan za ovakve projekte. Aplikacija koju vidite na ekranu je napravljena u Reactu (kao i Facebook, Instantgram, Twitter i mnogi drugi)."/>
      <Pitanje kod={6} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Što je to B2B SaaS?"/>
      <Odgovor kod={6} resizeBr={resizeBr} brPolja={brPolja} tekst="To je kratica za “business to business software as a service”. Radi se o poslovnom modelu gdje se ne kupuje sotfver, već se plaća mjesečna pretplata ili korištenje (pay per use). Ovaj model je posebno pogodan za biznis softver, zato što omogućuje dugotrajno usavršavanje aplikacije koje krajnji korisnik može usmjeravati, rješava individualne poslovne probleme koji se konstantno i nepredvidivo pojavljuju u realnom vremenu, uklanja brigu korisnika o održavnju softvera, servera te smanjuje inicijalnu cijenu na nulu."/>
      <Pitanje kod={7} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Koji je trenutni status aplikacije?"/>
      <Odgovor kod={7} resizeBr={resizeBr} brPolja={brPolja} tekst="Trenutna verzija je tzv. minimal viable product. To je faza razvoja gdje aplikacija ima osnovne funkcionalnosti da bi mogla biti korisna početnim klijentima. Najveći problem u ovom mom poslu je pronaći trzište i napraviti aplikaciju koju su klijenti na tržištu spremni platiti. Vi kao inženjer nikada ne znate prije samog pokušaja prodaje da li će se klijentima svidjeti vaš rad (groblje aplikacija je prepuno genijalnih rješenja za probleme koji ne postoje). Zato je ideja baciti aplikaciju rano na tržište i kroz povratne informacije je ubrzano usavršavati. Na taj način se izbjegava prevelika investicija u funkcionalnosti koje tržište ne treba (tzv. overengineering). Trenutna verzija omogućuje samo taksi obračune (Bolt i Uber). Međutim ja smatram da su potrebne minimalne preinake (max. 2 tjedna posla) da bude omogućeno potpuno funkcionalno korištenje i za dostavljačke platforme."/>
      <Pitanje kod={8} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Koja je cijena aplikacije?"/>
      <Odgovor kod={8} resizeBr={resizeBr} brPolja={brPolja} tekst="Početna cijena je nula. Vaše korištenje aplikacije plaća vaš radnik, i to po tjednom obračunu. Iznos koji plaća je 30 – 80 eurocenti po tjednom obračunu koji se napravi, na način da se pokažu manipulativni troškovi aplikacije u tom iznosu u obračunu i za koji se onda umanjuje radnikova tjedna isplata. Krajnja cijena ovisi o broju firmi/radnika koji koriste ovu aplikaciju, što ih je više, cijena je manja. Cijena uključuje server, održavanje i daljni razvoj. Ukoliko u budućnosti dođemo do konsenzusa da je aplikacija dovedena do savršenstva i da daljnji razvoj nije potreban, ja ga mogu zaustaviti uz smanjenje cijene. "/>
      <Pitanje kod={9} brPolja={brPolja} setBrPolja={setBrPolja} naslov="Ja imam ideju za novi feature koji mi treba. Koliko bi me koštalo da ga implementirate?"/>
      <Odgovor kod={9} resizeBr={resizeBr} brPolja={brPolja} tekst="To prvenstveno ovisi o tome da li je ta nova funkcionalnost korisna samo Vama ili može koristiti i drugim mojim klijentima. Ukoliko me uvjerite da koristi i drugima, razvoj tog featura ide na moju listu prioriteta, te ga možete očekivati u budućnosti. Cijena je u tom slučaju nula (uključeno u cijenu korištenja aplikacije). Ukoliko koristi samo vama, onda se moramo dogovoriti oko cijene, koja ovisi prvenstveno o broju sati potrebnih za implementaciju."/>
    </div>  
  )
}

function Pitanje({naslov="neko pitanje...", kod=-1, brPolja=-1, setBrPolja=()=>{}}) {
  const [sw, setSw] = React.useState(false);
  const [brKlik, setBrKlik] = React.useState(0);

  const r = React.useRef();
  const r1 = React.useRef();

  React.useEffect(()=>{
    if (brPolja === kod && !sw) {
      setSw(true);
    } else if (brPolja !== kod && sw) {
      setSw(false);
    } 
  }, [brPolja]);

  React.useEffect(()=>{
    if (sw) {
      setBrPolja(kod);
      r.current.style.color = "#d69e04";
      r1.current.style.backgroundColor = "#ddd";
    } else {
      //setBrPolja(-1);
      r.current.style.color = "black";
      r1.current.style.backgroundColor = "#eee";
    }
  }, [sw]);

  React.useEffect(()=>{
    if (brKlik !== 0) {
      if (sw)  setBrPolja(-1);
      setSw((prev)=>{return !prev});
    }
    //console.log("novi brKlik je " + brKlik);
  }, [brKlik]);

  return (
    <div ref={r1} className="naslov-pitanje" onClick={()=>{setBrKlik((prev)=>{return prev+1})}}>
      <p ref={r} className="naslov-pitanje-p">{naslov}</p>
    </div>
  )
}

function Odgovor({tekst="neki odgovor...", kod=-1, brPolja=-1, resizeBr=0}) {
  const r = React.useRef();
  const r1 = React.useRef();

  React.useEffect(()=>{
    let element = r.current;
    let cssObj = window.getComputedStyle(element, null);
    let svojstvo = cssObj.getPropertyValue("height");
  }, []);

  React.useEffect(()=>{
    if (brPolja === kod) {
      let element = r.current;
      let cssObj = window.getComputedStyle(element, null);
      let svojstvo = cssObj.getPropertyValue("height");
      svojstvo = parseFloat(svojstvo.split("p")[0]);
      console.log("postavljam visinu na " + svojstvo);
      r1.current.style.height = (svojstvo+25) + "px";
    } else {
      r1.current.style.height = "0px";
    }
  }, [brPolja, resizeBr]);

  return (
    <div ref={r1} className="odgovor">
      <p ref={r} className="odgovor-p">{tekst}</p> 
    </div>
  )
}

